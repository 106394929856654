import * as React from "react";
import { CheckIcon, DesktopComputerIcon } from "@heroicons/react/solid";
import Layout from "../components/layout";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";
import FormComponent from "../components/ui/FormComponent";
import ListComponent from "../components/ui/ListComponent";
import {StaticImage} from "gatsby-plugin-image";

const listOne = [
  {
    link: false,
    icon: CheckIcon,
    desc: "Разрабатывать кроссплатформенные приложения (сайты, мобильные приложения и десктопные приложения)",
  },
  {
    link: false,
    icon: CheckIcon,
    desc: "Получите знания и освоите навыки Javascript, React, React Native, HTML, CSS",
  },
  {
    link: false,
    icon: CheckIcon,
    desc: "Получите практические навыки и хорошее портфолио",
  },
  {
    link: false,
    icon: CheckIcon,
    desc: "После окончания курса вы можете откликнуться на вакансии - JavaScript разработчик, Frontend-разработчик, React-разработчик, React Native разработчик",
  },
];

const listTwo = [
  {
    icon: DesktopComputerIcon,
    desc: "Уверенный пользователь компьютера",
    link: false,
  },
  {
    icon: DesktopComputerIcon,
    desc: "При себе иметь ноутбук",
    link: false,
  },
];
const listThree = [
  {
    icon: CheckIcon,
    desc: "Большой опыт работы",
    link: false,
  },
  {
    icon: CheckIcon,
    desc: "Являемся настоящими специалистами в этой сфере",
    link: false,
  },
  {
    icon: CheckIcon,
    desc: "Реальные проекты",
    link: true,
  },
];

export default function CoursePage({location}) {

  return (
    <Layout location={location.pathname} title="Курсы программирования" description="Курсы программирования. Курс веб-программирования">
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Курсы программирования</TitleComponent>
        <div className="rounded-lg bg-white overflow-hidden shadow">
          <div className="flex flex-col items-center p-4 pt-10 sm:p-8">
            <StaticImage src="../../images/js.png" alt="Курсы программирования" />
            <MarginComponent small="sm:mt-8" margin="mt-10">
              <TitleComponent>Курс JavaScript разработчик</TitleComponent>
              <TitleComponent as="h3">Описание</TitleComponent>
              <p className="text-sm">
                Разработка кроссплатформенных приложений на JavaScript
              </p>
              <MarginComponent small="sm:mt-8" margin="mt-10"></MarginComponent>
              <TitleComponent as="h3">Чему вы научитесь</TitleComponent>
              <ListComponent data={listOne} />
              <MarginComponent small="sm:mt-8" margin="mt-10">
                <TitleComponent as="h3">Требования</TitleComponent>
                <ListComponent data={listTwo} bg="bg-yellow-500" />
              </MarginComponent>
              <MarginComponent small="sm:mt-8" margin="mt-10">
                <TitleComponent as="h3">Наши преимущества</TitleComponent>
                <ListComponent data={listThree} />
              </MarginComponent>
            </MarginComponent>
          </div>

          <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Старт курса
              </span>{" "}
              <br />
              <span className="text-gray-600">1 октябрь</span>
              <br />
              <span className="text-red-400">Открыт набор</span>
            </div>
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Длительность
              </span>{" "}
              <br />
              <span className="text-gray-600">4 месяцев</span>
              <br />
              <span className="text-gray-600">3 урока в неделю</span>
              <br />
              <span className="text-gray-600">1 урок - 120 минут</span>
            </div>
          </div>
          <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                Стоимость
              </span>{" "}
              <br />
              <span className="text-gray-600">800 000 сум</span>
            </div>
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-green-500 uppercase font-bold">
                В группе
              </span>{" "}
              <br />
              <span className="text-gray-600">10 человек</span>
            </div>
          </div>
        </div>
        <MarginComponent small="sm:mt-8" margin="mt-10">
          <TitleComponent>Записаться на курс</TitleComponent>
          <FormComponent />
        </MarginComponent>
        {/*<SocialButtons />*/}
      </MarginComponent>
    </Layout>
  );
}
