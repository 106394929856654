import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { LinkUnderlineComponent } from "./LinkUnderlineComponent";

export default function ListComponent({ data, bg = "bg-green-500" }) {
  return (
    <>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {data.map((item, i) => (
            <li key={i}>
              <div className="relative pb-8">
                {i !== data.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}

                <div className="relative flex items-center space-x-3">
                  <div>
                    <span
                      className={`h-8 w-8 ${bg} rounded-full flex items-center justify-center ring-8 ring-white`}
                    >
                      <item.icon className="h-5 w-5 text-white" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      {item.link && (item.desc !== "Batafsil LinkedIn profilida") ? (
                        <LinkUnderlineComponent href="/nashi-proekty">
                          {item.desc}
                        </LinkUnderlineComponent>
                      ) : item.desc === "Batafsil LinkedIn profilida" ? <LinkUnderlineComponent tag="a" href="https://www.linkedin.com/in/rakhimof/">{item.desc}</LinkUnderlineComponent> : (
                        <p className="text-sm text-gray-500">{item.desc}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
